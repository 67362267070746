import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import searchType from "../../search_config/search_type.json";
import minPrice from "../../search_config/min_price.json";
import maxPrice from "../../search_config/max_price.json";
import BedroomList from "../../search_config/bedrooms.json";
import propertyTypes from "../../search_config/property_type.json";
import "./assets/styles/_index.scss"
import { PageLinks } from "../../common/site/page-static-links";
import useAreaMultiSelect from '../SearchResults/PredictiveSearch/useAreaMutiSelect';
const Select = loadable(() => import("react-select"));
const MultiSelectInputDubai = loadable(() => import("../MultiSelectInput/MultiSelectInput"))

const BannerSearch = (props) => {

    const [priceRange, setPriceRange] = useState(false);
    const [minPriceVal, setMinPriceVal] = useState('');
    const [maxPriceVal, setMaxPriceVal] = useState('');
    const [selectedMinPriceValue, setSelectedMinPriceValue] = useState('');
    const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState('');
    const [searchTypeVal, setsearchTypeVal] = useState('buy');
    const [propertyval, setPropertyVal] = useState('');
    const [bedroomsVal, setBedroomsVal] = useState('')
    const { selectedAreas, handleRemoveArea, handleRemoveAreas, handleRemoveAllAreas } = useAreaMultiSelect({})
    const [first, ...rest] = selectedAreas
    // Min price
    const onChangeMinPrice = (e) => {
        setSelectedMinPriceValue(e.value);
    }
    // Min price

    // Max Price
    const onChangeMaxPrice = (e) => {
        setSelectedMaxPriceValue(e.value);
    }
    // Max Price 

    const isOffplan = props.offplanpage === true ? true : false
    // Dropdown react select styles
    const customStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                // backgroundColor: isSelected ? "#081D3C" : "null",
                // color: isSelected ? "#ffffff" : "#34373D",
                "&:hover": {
                    // color: "#ffffff",
                    cursor: "pointer",
                    // backgroundColor: "#081D3C",
                }
            }
        },
        control: styles => ({
            ...styles,
            backgroundColor: null,
            border: 0,
            paddingLeft: 0,
            outline: 0,
            boxShadow: "none",
            color: "#fff",
            fontSize: "1rem",
        }),
        valueContainer: styles => ({
            ...styles,
            fontSize: "1rem",
            paddingLeft: 0,
            lineHeight: "21px",
            cursor: "pointer",
        }),
        dropdownIndicator: styles => ({
            ...styles,
            color: "#fff",
        }),
        indicatorsContainer: styles => ({
            ...styles,
            color: "#fff",
            cursor: "pointer",
        }),
        indicatorSeparator: () => null,
        placeholder: defaultStyles => {
            return {
                ...defaultStyles,
                color: "#ffffff",
                marginLeft: 0,
            }
        },
    }
    // Dropdown react select styles
    //filters values
    const search_type_options = searchType;

    // Price range
    const priceRangeBtn = () => setPriceRange(price => !price);
    // Price range
    useEffect(() => {
        if (selectedAreas && selectedAreas.length > 0) {
            handleRemoveAllAreas(selectedAreas)
        }
    }, [])
    const handleSubmit = event => {
        event.preventDefault();
        let searchFilterResults = "";
        let areas = []
        if (selectedAreas.length > 0) {
            areas = selectedAreas.length > 0 && selectedAreas.map((item) => { return item.slug })
            areas = areas?.join(',')?.replaceAll(/,/g, "-and-")
        }
        if (areas && areas.length > 0) {
            searchFilterResults += searchTypeVal === "buy" ?
                `/property/for-sale/in-` + areas.replaceAll(/ /g, "-").toLowerCase() + '/'
                : `/property/to-rent/in-` + areas.replaceAll(/ /g, "-").toLowerCase() + '/'
        } else {
            searchFilterResults += searchTypeVal === "buy" ?
                `/${PageLinks.results_sales}/`
                : `/${PageLinks.results_lettings}/`
        }
        if (bedroomsVal) {
            searchFilterResults += bedroomsVal + "-and-more-bedrooms" + "/"
        }
        if (selectedMinPriceValue && selectedMaxPriceValue) {
            searchFilterResults += "between-" + selectedMinPriceValue + "-and-" + selectedMaxPriceValue + "/"
        } else if (selectedMinPriceValue) {
            searchFilterResults += "above-" + selectedMinPriceValue + "/"
        } else if (selectedMaxPriceValue) {
            searchFilterResults += "below-" + selectedMaxPriceValue + "/"
        }
        if (propertyval) {
            searchFilterResults += "type-" + propertyval + "/";
        }
        navigate(searchFilterResults);
    }

    return (
        <>
            <Row className='justify-content-center'>
                <Col>
                    <div className='banner-search-wrapper'>
                        <Form className="refine-form" method="post" onSubmit={handleSubmit} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
                            <input type="hidden" name="department" value={isOffplan ? "new_developments" : ""} />
                            <div className='banner-search-inner'>
                                <div className={`dropdown-select buy-dropdown`}>
                                    <Select
                                        options={search_type_options}
                                        value={search_type_options.value}
                                        name={"search_type"}
                                        placeholder={"Buy"}
                                        className={"select-control"}
                                        classNamePrefix={"react-select"}
                                        styles={customStyles}
                                        onChange={e => setsearchTypeVal(e.value)}
                                        isSearchable={false}
                                        components={{ DropdownIndicator: () => <i className={`icon icon-select-dropdown-dark`}></i>, IndicatorSeparator: () => null }}
                                    />
                                </div>
                                <div className={`banner-search-box`}>
                                    <MultiSelectInputDubai areaVal={process.env.GATSBY_DEFAULT_AREA} />
                                </div>
                                <div className={`price-range-wrapper`}>
                                    <button type="button" className={`d-flex align-items-center justify-content-between ${priceRange === true ? "active" : ""}`} onClick={priceRangeBtn}>Price<i className="icon icon-select-dropdown-dark"></i></button>
                                    {
                                        priceRange === true &&
                                        <div className="price-range">
                                            <div className="dropdown-select price-dropdown">
                                                <Select
                                                    options={searchTypeVal === "buy" ? minPrice['sales'] : minPrice['lettings']}

                                                    name={"search_type"}
                                                    placeholder={"Min Price"}
                                                    className={"select-control"}
                                                    classNamePrefix={"react-select"}
                                                    styles={customStyles}
                                                    onChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
                                                    isSearchable={false}
                                                    components={{ DropdownIndicator: () => <i className="icon icon-select-dropdown-dark"></i>, IndicatorSeparator: () => null }}
                                                />
                                            </div>
                                            <div className="dropdown-select price-dropdown">
                                                <Select
                                                    options={searchTypeVal === "buy" ? maxPrice['sales'] : maxPrice['lettings']}
                                                    name={"search_type"}
                                                    placeholder={"Max Price"}
                                                    className={"select-control"}
                                                    classNamePrefix={"react-select"}
                                                    styles={customStyles}
                                                    onChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}
                                                    isSearchable={false}
                                                    components={{ DropdownIndicator: () => <i className="icon icon-select-dropdown-dark"></i>, IndicatorSeparator: () => null }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <span className='divider'></span>
                                <div className={`dropdown-select bed-dropdown`}>
                                    <Select
                                        options={BedroomList}
                                        placeholder={"Beds"}
                                        name={"bedrooms"}
                                        className={"select-control"}
                                        classNamePrefix={"react-select"}
                                        styles={customStyles}
                                        isSearchable={false}
                                        onChange={e => setBedroomsVal(e.value)}
                                        components={{ DropdownIndicator: () => <i className={`icon icon-select-dropdown-dark`}></i>, IndicatorSeparator: () => null }}
                                    />
                                </div>
                                <span className='divider'></span>
                                <div className={`dropdown-select property-dropdown`}>
                                    <Select
                                        options={propertyTypes}
                                        name={"building_type"}
                                        placeholder={"All Types"}
                                        className={"select-control"}
                                        classNamePrefix={"react-select"}
                                        styles={customStyles}
                                        isSearchable={false}
                                        onChange={e => setPropertyVal(e.value)}
                                        components={{ DropdownIndicator: () => <i className={`icon icon-select-dropdown-dark`}></i>, IndicatorSeparator: () => null }}
                                    />
                                </div>
                                <div className='btn-wrapper'>
                                    <button name="submit" className="button-sec button-primary-dubai-red">Search</button>
                                </div>
                            </div>

                        </Form>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.themestyle === "dubai" && selectedAreas.length > 0 ?
                        <div className="seach-autolist-dubai-wrapper">
                            <ul className="list-inline search-autolist-dubai">
                                {selectedAreas && selectedAreas.map((item, index) => (
                                    <li className="list-inline-item autolist-block" key={index}><span>{item.name}</span> <i className="icon icon-search-autolist-close" onClick={() => handleRemoveArea(first)}></i></li>
                                ))}
                                <li className="list-inline-item autolist-clear" onClick={() => handleRemoveAllAreas(selectedAreas)}>Clear All</li>
                            </ul>
                        </div>
                        :
                        ""
                    }
                </Col>
            </Row>
        </>

    )
}

export default BannerSearch