import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import './assets/styles/_index.scss';
import ReviewImg from "../../images/google_reviews.svg";

const GoogleReview = (props) => {
    const { site } = useStaticQuery(
        graphql`
        query {
          site {
            siteMetadata {
                elfSight {
                  review
                  review_badge
                }
            }
          }
        }
      `
    )

    const elfData = site.siteMetadata?.elfSight
    return (
        <>
            {elfData.review && elfData.review_badge &&
                <div className={`google-review-wrapper d-md-flex align-items-center ${props.tag === "office-landing" || props.tag === "home-page" ? "d-flex" : "d-none"}`}>
                    <img src={ReviewImg} className="review-img" alt="" />
                    <ul className="list-inline review-list">
                        <li className="list-inline-item"><i className="icon icon-star-dark"></i></li>
                        <li className="list-inline-item"><i className="icon icon-star-dark"></i></li>
                        <li className="list-inline-item"><i className="icon icon-star-dark"></i></li>
                        <li className="list-inline-item"><i className="icon icon-star-dark"></i></li>
                        <li className="list-inline-item"><i className="icon icon-star-dark"></i></li>
                    </ul>
                    <div className="reviews-text"><span>4.9/5</span> from 316 Reviews</div>
                </div>
            }
        </>
    )
}

export default GoogleReview