import React, { useEffect } from "react"
import { usePropertyStore } from "../store/propertyStore"

const useAreaMultiSelect = props => {
  const areaVal = props?.areaVal
  const isBanner = props?.isBanner
  const searchTypeVar = props?.searchTypeVar
  const departmentVal = props?.departmentVal
  const [firstRender, setFirstRender] = React.useState(true)

  const [showSearchInput, setShowSearchInput] = React.useState(true)
  const searchText = usePropertyStore(state => state.searchText)
  const setSearchText = usePropertyStore(state => state.setSearchText)
  const searchSlug = usePropertyStore(state => state.searchSlug)
  const setSearchSlug = usePropertyStore(state => state.setSearchSlug)
  const areaJSON = usePropertyStore(state => state.areaJSON)
  const setAreaJSON = usePropertyStore(state => state.setAreaJSON)
  const selectedAreas = usePropertyStore(state => state.selectedAreas)
  const addSelectedArea = usePropertyStore(state => state.addSelectedArea)
  const setSelectedAreas = usePropertyStore(state => state.setSelectedAreas)
  const removeSelectedArea = usePropertyStore(state => state.removeSelectedArea)
  const removeSelectedAreas = usePropertyStore(
    state => state.removeSelectedAreas
  )
  const removeAllSelectedAreas = usePropertyStore(
    state => state.removeAllSelectedAreas
  )
  // let file = searchTypeVar === "buy" ? "residential-sales.json" : "residential-lettings.json"
  // let fetchURL = `${process.env.GATSBY_STRAPI_SRC}/json/${file}`
  const getAreas = async () => {
    try {
      fetch("/areas.json").then(res => res.json().then(areas => setAreaJSON(areas)));
      // const areas = await fetch(
      //   `${process.env.GATSBY_STRAPI_SRC}/json/areas.json`
      // )
      // let json = await areas.json()
      // setAreaJSON(json)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("🚀 ~ file: useAreaMultiSelect.js", error)
    }
  }
  useEffect(() => {
    getAreas()
    // if(sessionStorage.getItem("areas") !== null && sessionStorage.getItem("areas") !== undefined){
    //   setSelectedAreas(sessionStorage.getItem("areas"))

    // }
    // if (!isEmpty(json) && !isEmpty(areaVal)) {
    //   setAreaJSON(json)

    //   const selectedAreas = json.filter((item) =>
    //     areaVal.some((area) => area === item.slug)
    //   )
    //   setSelectedAreas(selectedAreas)
    // } else {
    //   setSelectedAreas([])
    // }
  }, [])

  useEffect(() => {
    if (firstRender) {
      if (selectedAreas.length) {
        setShowSearchInput(false)
        setFirstRender(false)
      }
    }
  }, [selectedAreas, firstRender])

  const handleSelectArea = area => {
    setSearchText("")
    setSearchSlug("")
    addSelectedArea(area)
    // sessionStorage.setItem("areas", JSON.stringify(selectedAreas))
  }

  const handleRemoveArea = area => {
    removeSelectedArea(area)
  }

  const handleRemoveAreas = areas => {
    removeSelectedAreas(areas)
  }

  const handleRemoveAllAreas = () => {
    removeAllSelectedAreas()
  }

  return {
    searchText,
    setSearchText,
    areasSuggestionList: areaJSON,
    searchSlug,
    setSearchSlug,
    selectedAreas,
    showSearchInput,
    setShowSearchInput,
    handleSelectArea,
    handleRemoveArea,
    handleRemoveAreas,
    handleRemoveAllAreas,
  }
}

export default useAreaMultiSelect
